import React from 'react';

import styled from '@emotion/styled'
import { Image, Icon, Grid, Button } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

const Footer = styled('footer')`
  padding: 30px 0;
  justify-content: center;

  background-color: white;
  align-items: center;
  height : 100%;
  display : flex;
  flex-shrink: 0;
  flex-direction : row;
  bottom : 0;
  width : 100%;
  position: relative;
`

const TagLine = {
  _ : styled('div')`
    font-size: 1rem;
    text-align: justify;
    color : black
    text-transform : uppercase;
    display : flex;
    flex-direction : column;
    justify-content : center;
  `,

  Title : styled('h2')`
    text-align: justify;

    font-size: 1rem;
    color : inherit;
  font-weight : 600;
  `,

  Author : styled('div')`
  font-weight : 200;
  `
}

const Menu = {
  _ : styled('div')`
    font-size: 1rem;
    color : grey;
    display : flex;
    flex-direction : column;
    justify-content : flex-start;
  `,

  Title : styled('h3')`
  font-family: Belwe Std Bold;
    text-transform : uppercase;
    color : black;
    font-size: 1.4rem;
  font-weight : 600;
  padding-bottom : 1rem;
  `,

  Link : styled('div')`
    font-size : 1rem;
    line-height : 1rem;

  &:hover {
    color: black
  }
`
}

const Link = styled('span')`
  font-size : 1rem;
  line-height : 1rem;

  &:hover {
    color: black
    text-decoration: underline
  }
`


const Right = styled('span')`
  float: right
`

const actualDay = {
  'color': 'goldenrod',
  'fontWeight': 'bold'
}

const elseDay = {
  'color': 'gray'
}


const FooterComponent = () => {
  const navigate = useNavigate();


  return <Footer>
  <Grid stackable doubling container columns={3} padded relaxed celled='internally'>
    <Grid.Row>
        <Grid.Column width={6}>
          <TagLine._>
            <Menu.Title onClick={() => window.location.href='http://maps.google.com/?q=10 Rue de la Cloche, Fontainebleau'}>
              Dany-Coiff'
            </Menu.Title>
            <p>
                Depuis { (new Date()).getFullYear() - 1984 } ans, notre salon de coiffure mixte vous apporte les conseils qui mettront en valeur la beauté de votre visage, selon vos envies et votre style.
                <br/><br/>
                Du court au long, du naturel à l'excentrique, nous réalisons toutes vos demandes de coupes de cheveux.
                <br/><br/>
                Dans une ambiance familiale et chaleureuse, il vous sera possible de boire un café, un thé ou un chocolat chaud pour patienter en toute quiétude en attendant votre service.
                <br/><br/>
                <br/><br/>
            </p>
            <div>
              <a rel="noopener noreferrer" target="_blank" href='https://www.facebook.com/profile.php?%20id=100012355474171'>
                <Button icon='facebook f' color='facebook' size='large' />
              </a>
              <a rel="noopener noreferrer" target="_blank" href='https://www.instagram.com/danycoiffure'>
                <Button icon='instagram' color='instagram' size='large' />
              </a>
              <a rel="noopener noreferrer" target="_blank" href='https://fr.linkedin.com/in/caroline-dany-coiff-3b4665154'>
                <Button icon='linkedin' color='linkedin' size='large' />
              </a>
            </div>
            <div>
              <br/><br/>
              <Link target="_blank" onClick={() => navigate('/mentionlegales')}>Mention Legales  </Link> /
              <Link target="_blank" onClick={() => navigate('/marques')}>  Marques</Link>
            </div>
          </TagLine._>

        </Grid.Column>
        <Grid.Column width={4}>
          <Menu._>
            <Menu.Title>Horaires</Menu.Title>
            <i>
              <Icon color='orange' name='warning circle' />
                Les horaires peuvent varier pendant la crise sanitaire. Appelez nous pour confirmation.
              <Icon color='orange' name='warning circle' />
            </i>
            <Menu.Link style={ (new Date()).getDay() === 1 ? actualDay : elseDay }>Lundi : <Right>Fermé</Right></Menu.Link><br/>
            <Menu.Link style={ (new Date()).getDay() === 2 ? actualDay : elseDay }>Mardi : <Right>8h30 - 12h00</Right><br/><Right>14h00 - 19h00</Right></Menu.Link><br/>
            <Menu.Link style={ (new Date()).getDay() === 3 ? actualDay : elseDay }>Mercredi : <Right>9h30 - 18h00</Right></Menu.Link><br/>
            <Menu.Link style={ (new Date()).getDay() === 4 ? actualDay : elseDay }>Jeudi : <Right>8h30 - 19h00</Right></Menu.Link><br/>
            <Menu.Link style={ (new Date()).getDay() === 5 ? actualDay : elseDay }>Vendredi : <Right>8h30 - 20h00</Right></Menu.Link><br/>
            <Menu.Link style={ (new Date()).getDay() === 6 ? actualDay : elseDay }>Samedi : <Right>8h30 - 18h30</Right></Menu.Link><br/>
            <Menu.Link style={ (new Date()).getDay() === 7 ? actualDay : elseDay }>Dimanche : <Right>Fermé</Right></Menu.Link><br/>
          </Menu._>
          <br/>
          <br/>
          <br/>

          <Menu._>
            <Menu.Title onClick={() => window.location.href='http://maps.google.com/?q=10 Rue de la Cloche, Fontainebleau'}>Nous contacter</Menu.Title>
            <Menu.Link onClick={() => window.location.href='http://maps.google.com/?q=10 Rue de la Cloche, Fontainebleau'}>10 rue de la Cloche</Menu.Link><br/>
            <Menu.Link onClick={() => window.location.href='http://maps.google.com/?q=10 Rue de la Cloche, Fontainebleau'}>77 3000 Fontainebleau</Menu.Link><br/>
            <Menu.Link onClick={() => window.location.href='tel:0164223015'}>01 64 22 30 15</Menu.Link><br/>
          </Menu._>
        </Grid.Column>
        <Grid.Column width={6}>
          <Menu._>
            <Menu.Title>Tarifs</Menu.Title>
            <Menu.Link>COUPE BRUSHING cheveux courts : <Right>42,00 €</Right></Menu.Link><br/>
            <Menu.Link>COUPE BRUSHING cheveux carré : <Right>46,50 €</Right></Menu.Link><br/>
            <Menu.Link>BRUSHING cheveux courts : <Right>23,00 €</Right></Menu.Link><br/>
            <Menu.Link>BRUSHING cheveux carré : <Right>27,50 €</Right></Menu.Link><br/>
            <Menu.Link>COUPE MISE EN PLIS cheveux courts : <Right>40,50 €</Right></Menu.Link><br/>
            <Menu.Link>MISE EN PLIS cheveux courts : <Right>21,50 €</Right></Menu.Link><br/>
            <Menu.Link>MISE EN PLIS cheveux longs : <Right>24,00 €</Right></Menu.Link><br/>
            
            <Menu.Link>PERMANENTE TRAITANTE : <Right>38,00 €</Right></Menu.Link><br/>
            <Menu.Link>PERMANENTE SANS AMNONIAQUE : <Right>41,00 €</Right></Menu.Link><br/>
            <Menu.Link>COLORATION D'OXYDATION : <Right>28,50 €</Right></Menu.Link><br/>
            <Menu.Link>COLORATION TEMPORAIRE : <Right>26,50 €</Right></Menu.Link><br/>
            <Menu.Link>DECOLORATION ACTIVEE : <Right>28,50 €</Right></Menu.Link><br/>
            <Menu.Link>BALAYAGE à partir de : <Right>41,50 €</Right></Menu.Link><br/>
            <Menu.Link>SHAMPOOING TRAITANT SP : <Right>6,00 €</Right></Menu.Link><br/>
            <Menu.Link>COUPE HOMME : <Right>25,00 €</Right></Menu.Link><br/>
            <i>Venez nous rencontrer et découvrir nos autres prestations.</i>
            <i>T.V.A et Service 15% compris.</i>
          </Menu._>
          <br/>
        </Grid.Column>
    </Grid.Row>
  </Grid>
  </Footer>
}

export default FooterComponent;
