import React, { Component, Fragment } from 'react';

import Blur from 'react-blur'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet';
import { Routes, Route } from "react-router-dom";

import './App.css'
import Main from './Main'
import Footer from './Footer'
import Marques from './Marques'
import MentionLegales from './MentionLegales'

const Global = styled('div')`
  min-height: 85vh;
  display: flex;
  flex-direction: column;
`


class App extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Dany Coiff' - Salon de coiffure à Fontainebleau - Coiffures hommes, femmes et enfants</title>
            <meta name="author" content="Nascis" />
            <meta name="description" content="Salon de coiffure à Fontainebleau. Depuis 30 ans, notre salon de coiffure mixte vous apporte les conseils qui mettront en valeur la beauté de votre visage, selon vos envies et votre style." />
            <meta name="copyright" content="Agence Nascis - nascis.fr" />
            <meta name="keywords" content="Coiffure, coiffeur, Fontainebleau, bellifontaines, bellifontains, brushing, coupe, shampooing, hommes, femmes, enfants, couleurs, mixte, beauté du visage, visage, beauté, cheveux, cheveux courts, cheveux longs, mise en plis, permanente, coloration, décoloration, balayage, dany coiff, dany, coiff, dany-coiff" />

            <meta itemprop="name" content="Dany Coiff' - Salon de coiffure à Fontainebleau - Coiffures hommes, femmes et enfants" />
            <meta itemprop="description" content="Salon de coiffure à Fontainebleau. Depuis 30 ans, notre salon de coiffure mixte vous apporte les conseils qui mettront en valeur la beauté de votre visage, selon vos envies et votre style." />
            <meta itemprop="image" content="http://dany-coiff.nascis.fr/wella.png" />

            <link rel="canonical" href="http://dany-coiff.nascis.fr" />
            <meta property="og:title" content="Dany Coiff' - Salon de coiffure à Fontainebleau - Coiffures hommes, femmes et enfants" />
            <meta property="og:type" content="article" />
            <meta property="og:description" content="Depuis 30 ans, notre salon de coiffure mixte vous apporte les conseils qui mettront en valeur la beauté de votre visage, selon vos envies et votre style." />
            <meta property="og:url" content="http://dany-coiff.nascis.fr" />
            <meta property="og:image" content="http://dany-coiff.nascis.fr/wella.png" />
            <meta property="og:site_name" content="Salon de coiffure à Fontainebleau" />
            <meta property="og:locale" content="fr_FR" />

            <meta name="twitter:card" content="Dany Coiff' - Salon de coiffure à Fontainebleau - Coiffures hommes, femmes et enfants" />
            <meta name="twitter:title" content="Dany Coiff' - Salon de coiffure à Fontainebleau - Coiffures hommes, femmes et enfants" />
            <meta name="twitter:description" content="Salon de coiffure à Fontainebleau. Depuis 30 ans, notre salon de coiffure mixte vous apporte les conseils qui mettront en valeur la beauté de votre visage, selon vos envies et votre style." />
            <meta name="twitter:image" content="http://dany-coiff.nascis.fr/wella.png" />
        </Helmet>
        <Global>
          <Routes>  
            <Route index path="/" element={<Footer />} />
            <Route path="/mentionlegales" element={<MentionLegales />} />
            <Route path="/marques" element={<Marques />} />
          </Routes>
        </Global>
      </Fragment>
    );
  }
}

export default App;
