import React from 'react'

import styled from '@emotion/styled'
import { Image } from 'semantic-ui-react'

const Container = styled('div')`
  padding: 80px;
  background-color: white;
  height : 100%;
  bottom : 0;
  width : 100%;
  position: relative;
`

const Title = styled('h2')`
  font-family: Belwe Std Bold;
  text-transform : uppercase;
  color : inherit;
  font-size: 1.4rem;
  font-weight : 600;
  padding-bottom : 1rem;
`

const Marques = () => (
  <Container>
    <Title>Marques</Title>

    <Image.Group size='medium'>
      <Image  as='a' rel="noopener noreferrer" target='_blank' href='https://www.wella.com/professional/fr-FR/home' src='./wella.png' centered />
      <Image  as='a' rel="noopener noreferrer" target='_blank' href='https://www.nioxin.com/fr-FR' src='./nioxin.png' centered />
      <Image  as='a' rel="noopener noreferrer" target='_blank' href='https://www.wella.com/professional/fr-FR/products/color-information/wellaplex_blond_maker' src='./wellaplex.jpg' centered />
      <Image  as='a' rel="noopener noreferrer" target='_blank' href='https://www.innosensellc.com/' src='./innosense.jpg' centered />
    </Image.Group>
  </Container>
)

export default Marques
