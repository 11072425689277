import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Routes } from "react-router-dom";

const container = document.getElementById('app');
const root = createRoot(container); 

root.render(  <React.StrictMode>

  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

serviceWorker.unregister();
