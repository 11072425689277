import React from 'react'

import styled from '@emotion/styled'

const Container = styled('div')`
  padding: 80px;
  background-color: white;
  height : 100%;
  bottom : 0;
  width : 100%;
  position: relative;
`

const Title = styled('h2')`
  font-family: Belwe Std Bold;
  text-transform : uppercase;
  color : inherit;
  font-size: 1.4rem;
  font-weight : 600;
  padding-bottom : 1rem;
`

const SubTitle = styled('h3')`
  font-family: Belwe Std Bold;
  text-transform : uppercase;
  color : inherit;
  font-size: 1.2rem;
  font-weight : 400;
  padding-bottom : 1rem;
`


const MentionLegales = () => <Container>
    <Title>Mention Legales</Title>

    <SubTitle>I. Editeur</SubTitle>
    <p>
      Le site web « www.dany-coiff.fr » est la propriété de Nascis, micro-entreprise, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 799 349 402.</p>
      <p>Le site « www.dany-coiff.fr » est hébergé par la société Online.net</p>

      <p>Le site « www.dany-coiff.fr » est régi par le droit français ; les visiteurs ayant accès au site de l’étranger, doivent s’assurer du respect des lois localement applicables.</p>

      <p>Les mentions légales pouvant être modifiées à tout moment et sans préavis, nous vous engageons à les consulter régulièrement.</p>

    <SubTitle>II. Droits de pripriété intellectuelle</SubTitle>

    <p>Le site et chacun des éléments qui le composent (tels que marques, logos, photographies, images, illustrations, textes, clips vidéo…) sont la propriété exclusive de Nascis, seul habilité à utiliser les droits de propriété intellectuelle y afférents, celle-ci ne concédant aucune licence, ni aucun autre droit que celui de consulter le site.</p>

      <p>La reproduction de tous documents publiés sur le site est seulement autorisée aux fins exclusives d’information pour un usage strictement personnel et privé. Toute reproduction ou représentation, intégrale ou partielle, du site, ou de l’un quelconque des éléments qui le compose, sur quelque support que ce soit, à d’autres fins, et notamment commerciales, est expressément interdite.</p>

      <p>Toute demande d’autorisation doit être adressée à : contact@nascis.fr. Toute utilisation non autorisée constitue une contrefaçon sanctionnée pénalement.</p>

      <SubTitle>III. Exclusion de garantie et de responsabilité</SubTitle>

      <p>
        Nascis ne saurait être tenue pour responsable des dommages directs ou indirects qui pourraient résulter de l’accès au site ou de l’utilisation du site et/ou de ces informations, y compris l’inaccessibilité, les pertes de données, détériorations, destructions ou virus qui pourraient affecter l’équipement informatique de l’utilisateur et/ou de la présence de virus sur son site.</p>

        <p>Bien que Nascis s’efforce de fournir un contenu fiable sur son site, elle ne garantit pas que son contenu soit exempt d’inexactitudes ou d’omissions et ne saurait être tenue pour responsable des erreurs ou omissions, d’une absence de disponibilité des informations et des services. Toute erreur ou omission devra être notifiée en adressant un courriel à : contact@nascis.fr</p>

        <p>Nascis se réserve à tout moment et sans préavis le droit d’apporter des améliorations et/ou des modifications au contenu de son site. Il est précisé qu’à des fins de maintenance, de mise à jour, et pour toute autre raison notamment d’ordre technique, l’accès au Site pourra être interrompu. Nascis n’est en aucun cas responsable de ces interruptions et des conséquences qui peuvent en découler pour l’Utilisateur.</p>

      <SubTitle>IV. Liens hypertextes</SubTitle>
      <p>
        Le site peut inclure des liens vers d’autres sites ou d’autres sources Internet. Dans la mesure où Nascis ne peut contrôler ces sites et ces sources externes, Nascis ne peut être tenue pour responsable de la mise à disposition de ces sites et sources externes, et décline ainsi toute responsabilité quant aux contenus, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes. Ces liens sont proposés aux utilisateurs du site en tant que service. La décision d’activer les liens appartient exclusivement à l’Utilisateur.
      </p>

      <SubTitle>V. Modification de la notice</SubTitle>
      <p>
      Nascis conserve la possibilité de modifier à tout moment la présente notice d’utilisation du Site, notamment afin de respecter toute nouvelle réglementation ou législation ou afin d’améliorer la consultation du Site par l’Utilisateur. Toute modification d’utilisation du Site sera intégrée dans la présente notice.
      </p>

      <SubTitle>VI. Utilisation de cookies</SubTitle>
      <p>
        Comme beaucoup d’autres sites Internet, nous pouvons utiliser des “cookies” pour collecter et enregistrer les informations concernant l’utilisation du Site. Les cookies sont conçus pour nous aider à vous reconnaître comme un précédent utilisateur du Site afin de vous offrir le service le plus adapté et personnalisé à votre profil, et d’augmenter ainsi votre plaisir de visiter à nouveau le Site.
      </p>
      <p>
        Les cookies que nous utilisons ne peuvent pas extraire de votre disque dur des informations personnelles permettant de vous identifier autrement que sous forme de cookies, ni transmettre de virus informatique. Les cookies nous permettent de réaliser des statistiques de visite de note site Internet.
      </p>
      <p>
        Nous utilisons les cookies seulement pour recueillir les informations mentionnées dans cette Charte et, en aucune façon, des informations à caractère personnel ou nominatives permettant de vous identifier sans votre consentement.
      </p>
      <p>
        Afin de mesurer votre intérêt pour les différentes rubriques de notre Site, nous nous réservons le droit de procéder à l’analyse des comportements des utilisateurs du Site. Toutes les informations qui seront collectées à cette occasion seront utilisées dans le seul dessein d’améliorer son contenu, d’accroître le plaisir des utilisateurs de naviguer sur le Site, et de personnaliser le contenu et/ou la présentation des pages.
      </p>
      <p>
        Vous avez la possibilité d’intervenir au niveau des cookies. En modifiant la configuration de votre navigateur, vous pouvez : accepter l’ensemble des cookies, être averti lors de l’activation d’un cookie, ou rejeter l’ensemble des cookies. Toutefois, il est probable alors que vous rencontriez des difficultés pour naviguer sur le Site dans les meilleures conditions.
      </p>
  </Container>

export default MentionLegales
