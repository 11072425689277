import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Button, Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { MobileView } from "react-device-detect"

const Container = styled('div')`
  z-index: 9999;
  padding: 50px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
`

const Title = styled('h1')(css`
  font-size: 10rem;
  @media (max-width: 1000px) {
    font-size: calc(2rem + .4vw);
    margin-bottom: calc(30px + .4vw);
  }
  font-family: Belwe Std Bold;
`)

const SubTitle = styled('h2')`
  font-size: 1.4rem;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: calc(0.8rem + .4vw);
  }
  font-family: Belwe Std Bold;
`

const Buttons = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.2rem;
`

const Main = () => {
  const navigate = useNavigate();
  return <Container>
    <Title onClick={() => navigate('/')}>Dany-Coiff'</Title>
    <SubTitle>Coiffeur hommes, femmes et enfants à Fontainebleau depuis 1984</SubTitle>
    <br/>
    <MobileView>
      <Buttons>
        <Button
          size='medium'
          onClick={() => { window.location.href='tel:0164223015'}}
          inverted>
          <Icon circular name='phone' />
          Appelez-nous
        </Button>
        <Button
          size='medium'
          onClick={() => {window.location.href='http://maps.google.com/?q=10 Rue de la Cloche, Fontainebleau'
          }}
          inverted>
          <Icon circular name='map marker alternate' />
          Retrouvez-nous
        </Button>
      </Buttons>
    </MobileView>
  </Container>
}

export default Main
